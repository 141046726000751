var resizeTimer;
window.addEventListener('resize', function (event) {
  if (!resizeTimer) {
    clearTimeout(resizeTimer);
  }
  resizeTimer = setTimeout(function () {
    onResize();
  }, 200);
});
function onResize(){
  document.getElementById('main-body').style.minHeight = window.innerHeight+'px';
}
onResize();


document.getElementById('copyright_year').innerHTML = getNow();
function getNow() {
  var nowYear = new Date().getFullYear();
  return nowYear;
}
